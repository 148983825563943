<template>
  <transition name="fade" mode="out-in">
    <div class="screen-wrap">
      <v-app class="hmc-app">
        <v-main class="main-body">
          <ApiErrorDialog v-if="api.isError" :api="api" />
          <v-container class="app-body">
            <div class="pe-2 ps-3 app-title text-h5">
              {{ $t("string.classmates") }}
              <v-spacer></v-spacer>
              <CloseBtn size="40" :callback="exit" />
            </div>
            <div class="px-5">
              <div class="d-flex justify-center">
                <v-select
                  color="brown"
                  v-model="selectedSchool"
                  :items="schools"
                  item-text="text"
                  item-value="id"
                  dense
                  rounded
                  flat
                  hide-details
                  class="mb-2 list-card-x"
                  @change="loadClasses"
                >
                  <template v-slot:selection="data">
                    <v-list-item-avatar color="primary">
                      <img :src="data.item.logoUrl" v-if="data.item.logoUrl" />
                      <span class="white--text text-h5" v-else>{{
                        data.item.name.charAt(0)
                      }}</span>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <div>{{ data.item.name }}</div>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar color="primary">
                      <img :src="data.item.logoUrl" v-if="data.item.logoUrl" />
                      <span class="white--text text-h5" v-else>{{
                        data.item.name.charAt(0)
                      }}</span>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        data.item.name
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </div>
              <div class="d-flex justify-center">
                <v-select
                  color="brown"
                  v-model="selectedClass"
                  :items="classes"
                  item-text="nameX"
                  item-value="id"
                  dense
                  rounded
                  flat
                  hide-details
                  class="mb-2 py-1 list-card-x"
                  @change="loadClassMates"
                >
                </v-select>
              </div>
              <Loader :isLoading="api.isLoading" />
              <div class="py-6">
                <v-card
                  class="d-flex my-2 list-card"
                  v-for="friend in friends"
                  :key="friend.name"
                  flat
                >
                  <AbstractAvatar
                    :avatar="friend.avatar"
                    :height="80"
                    :width="80"
                    class="ma-2"
                  ></AbstractAvatar>
                  <v-card flat color="transparent">
                    <v-card-title class="pt-1 brown--text">
                      {{ friend.name }}
                    </v-card-title>
                    <v-card-subtitle class="pb-2">
                      Level {{ friend.level }}
                    </v-card-subtitle>
                    <v-card-text class="d-flex flex-wrap pb-2">
                      <v-img
                        v-for="achivement in friend.PlayerAchievement"
                        :key="achivement.AchievementLevel.id"
                        :src="achivement.AchievementLevel.imageUrl"
                        max-width="30"
                      >
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-card>
              </div>
            </div>
          </v-container>
        </v-main>
      </v-app>
    </div>
  </transition>
</template>
      
  <script>
import { mapState } from "vuex";
import AvatarSkeleton from "@/components/skeleton/Avatar";
export default {
  components: {
    AbstractAvatar: () => ({
      component: import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
      loading: AvatarSkeleton,
      delay: 200,
    }),
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    selectedSchool: "",
    schools: [],
    selectedClass: "",
    classes: [],
    friends: [],
  }),
  async created() {
    await this.loadSchools();
  },
  mounted() {
    //
  },
  methods: {
    exit() {
      this.$router.push({
        name: "PageExploreLanding",
      });
    },
    async loadSchools() {
      // this.api.method = "get";
      this.api.url =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/friends/institution/load";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
        console.error(e);
      };
      this.api.callbackSuccess = async (resp) => {
        this.api.isLoading = false;
        this.schools = resp.Institution;
        this.selectedSchool = this.auth.Institution
          ? this.auth.Institution.id
          : this.schools[0].id;

        await this.loadClasses();
      };
      await this.$api.fetch(this.api);
    },
    async loadClasses() {
      // this.api.method = "get";
      this.api.url =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/friends/classroom/load";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
        console.error(e);
      };
      this.api.callbackSuccess = async (resp) => {
        this.api.isLoading = false;
        this.classes = resp.Classroom;
        this.classes.forEach((element) => {
          element["nameX"] = element.name + " - " + element.year;
        });
        this.selectedClass =
          this.auth.Institution &&
          this.auth.Institution.id == this.selectedSchool &&
          this.auth.Classroom
            ? this.auth.Classroom.id
            : this.classes[0].id;
        await this.loadClassMates();
      };
      this.api.params = {
        institutionId: this.selectedSchool,
      };
      await this.$api.fetch(this.api);
    },
    async loadClassMates() {
      // this.api.method = "get";
      this.api.url =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/friends/student/load";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
        console.error(e);
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.friends = resp;
      };
      this.api.params = {
        classroomId: this.selectedClass,
      };
      await this.$api.fetch(this.api);
    },
  },
};
</script>

<style scoped>
.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.main-body {
  /*background-color: #c7e5e9;*/
  background-color: rgba(214, 132, 49, 0.3);
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}

.list-card-x {
  color: #785c3a;
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #f7efe3;
  overflow: hidden !important;
}

.list-card {
  padding: 5px;
  color: #785c3a;
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #fff9f1;
  overflow-x: hidden !important;
}
</style>